import {
    GET_PERMINTAAN_REREJUNA_LIST_INIT,
    GET_PERMINTAAN_REREJUNA_LIST_SUCCESS,
    GET_PERMINTAAN_REREJUNA_LIST_ERROR,
    GET_PERMINTAAN_REREJUNA_INIT,
    GET_PERMINTAAN_REREJUNA_SUCCESS,
    GET_PERMINTAAN_REREJUNA_ERROR,
    UPDATE_PERMINTAAN_REREJUNA_LIST_INIT,
    UPDATE_PERMINTAAN_REREJUNA_LIST_SUCCESS,
    UPDATE_PERMINTAAN_REREJUNA_LIST_ERROR,
    PermintaanRerejunaActions
  } from '../../types/actions/PermintaanRerejuna.action';
  
  const initState = {
    list: [],
    permintaan:{},
    loading: false,
    updated: false,
    error: false,
    errorMessage:"",
  };
  
  const permintaan = (state = initState, action: PermintaanRerejunaActions) => {
    switch (action.type) {
      case GET_PERMINTAAN_REREJUNA_LIST_INIT: {
        return {...initState, loading: true};
      }
      case GET_PERMINTAAN_REREJUNA_LIST_SUCCESS: {
        return {...state, list: action.payload, loading:false};
      }
      case GET_PERMINTAAN_REREJUNA_LIST_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      case GET_PERMINTAAN_REREJUNA_INIT: {
        return {...initState, loading: true};
      }
      case GET_PERMINTAAN_REREJUNA_SUCCESS: {
        return {...state, permintaan: action.payload, loading:false};
      }
      case GET_PERMINTAAN_REREJUNA_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      case UPDATE_PERMINTAAN_REREJUNA_LIST_INIT: {
        return {...action.payload.state, loading: true};
      }
      case UPDATE_PERMINTAAN_REREJUNA_LIST_SUCCESS: {
        return {...action.payload.state, list: action.payload.data, loading:false};
      }
      case UPDATE_PERMINTAAN_REREJUNA_LIST_ERROR: {
        return {...action.payload.state, error:true, errorMessage: action.error, loading:false};
      }
      default:
        return state;
    }
  };
  export default permintaan;
  