import React from 'react';

export const chatPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/chats',
        component: React.lazy(() => import('./Diskusi')),
      },
    ],
  },
//   {
//     auth: ['user'],
//     routes: [
//       {
//         path: '/garapin-mitra',
//         component: React.lazy(() => import('./garapinMitra')),
//       },
//     ],
//   },
//   {
//     auth: ['user'],
//     routes: [
//       {
//         path: '/add-mitra/:uid',
//         component: React.lazy(() => import('./addMitra'))
//       },
//     ],
//   },
  
];
