
import { CREATE_PRODUCT_DATA, GET_PRODUCT_DATA, GET_PRODUCT_LIST, ProductActionTypes } from 'types/actions/Product.action';
import { Product } from 'types/models/Product';

const initialState: {
  list: Product[] | undefined | null;
  product: Product | undefined | null
} = {
  list: null,
  product: null
};

const ProductReducer = (state = initialState, action: ProductActionTypes) => {
  switch (action.type) {
    case GET_PRODUCT_LIST:
      return {
        ...state,
        list: action.payload,
      };
    case GET_PRODUCT_DATA:
      return {
        ...state,
        category: action.payload,
      };
    case CREATE_PRODUCT_DATA:
      return {
        ...state,
        list: state.list.push(action.payload),
      };
    

    default:
      return state;
  }
};
export default ProductReducer;
