

import { Product } from 'types/models/Product';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';
export const GET_PRODUCT_DATA = 'GET_LIST_DATA';
export const CREATE_PRODUCT_DATA = 'CREATE_LIST_DATA';

export interface GetProductList {
  type: typeof GET_PRODUCT_LIST;
  payload: Product[];
}
export interface GetProductData {
  type: typeof GET_PRODUCT_DATA;
  payload: Product;
}
export interface CreateProductData {
  type: typeof CREATE_PRODUCT_DATA;
  payload: Product;
}


export type ProductActionTypes =
  | GetProductList
  | GetProductData
  | CreateProductData;
