import { firebase } from '@crema/services/auth/firebase/firebase';
import {
    GarapinMitraActions,
    GET_SINGLE_MITRA_INIT,
    GET_SINGLE_MITRA_ERROR,
    GET_SINGLE_MITRA_SUCCESS
  } from '../../types/actions/GarapinMitra.action';

  interface InitState {
    data: null| firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>;
    loading: boolean
    updated: boolean
    updating: boolean
    error: boolean
    errorMessage: string
  }
  
  const initState:InitState = {
    data: null,
    loading: false,
    updated: false,
    updating: false,
    error: false,
    errorMessage:"",
  };
  
  const GarapinSingleMitra = (state = initState, action:GarapinMitraActions) => {
    switch (action.type) {
      case GET_SINGLE_MITRA_INIT: {
        return {...initState, data:null, loading: true};
      }
      case GET_SINGLE_MITRA_SUCCESS: {
        return {...state, data: action.payload, loading:false};
      }
      case GET_SINGLE_MITRA_ERROR: {
        return {...state, error:true, errorMessage: action.error, data: null, loading:false};
      }
      default:
        return state;
    }
  };
  export default GarapinSingleMitra;
  