import { firestore } from "@crema/services/auth/firebase/firebase";

export const ChatRoomType = {
    PRODUKSI_DISCUSSION: 'produksi_discussion',
    CHAT: 'chat'
}

export const getChatRooms = async (roomType:string) => {
    try {
        const snap = await firestore.collection(roomType).where('last_message', "!=", '').get();
        //Sort the "not empty" chatroom descending.
        const snapOrder = snap.docs.sort(function(a, b){return b.data().last_message_at - a.data().last_message_at});
        return snapOrder;
    } catch (e) {
        console.error(e);
        return e;
    }
}

export const getSingleRoomMsg = async (roomId:string, roomType:string) => {
    try {
        const snap = await firestore.collection(roomType+'/'+roomId+'/messages').where('deleted', '==', false).get();
        const snapChats = snap.docs.sort(function(a, b){return a.data().sent_at - b.data().sent_at});
        return snapChats;
    } catch (e) {
        return e;
    }
}


export const getUserName = async (uid:string) => {
    try {
        if(uid === undefined || uid === null) return 'Unknown User (user not provided)'
        const snap = await firestore.collection('users').doc(uid).get();
        return snap?.get('nama') ?? 'Unknown User (user not found)';
    } catch (e) {
        throw e;
    }
}

export const getUserOnlineStatus = async (uid:string) => {
    try {
        if(uid === undefined || uid === null) return 'Status unknown (user not provided)'
        const snap = await firestore.collection('users').doc(uid).get();
        return snap?.get('status_chat') ?? 'Status unknown (user not found)';
    } catch (e) {
        throw e;
    }
}