import Settings from './Setting';
import CommonReducer from './CommonReducer';
import Auth from './Auth';
import Dashboard from './Dashboard';
import Ecommerce from './Ecommerce';
import ChatApp from './ChatApp';
import MailApp from './MailApp';
import ScrumBoardReducer from './ScrumboardApp';
import ContactApp from './ContactApp';
import WallApp from './WallApp';
import ToDoApp from './ToDoApp';
import GarapinUser from './GarapinUser';
import GarapinMitra from './GarapinMitra';
import Orders from './Orders';
import Templates from './Template';
import Permintaan from './Permintaan';
import PermintaanRerejuna from './PermintaanRerejuna';
import Discussion from './Discussion';
import ConfigReducer from './Config';
import GarapinSingleMitra from './GarapinSingleMitra';
import Shipping from './Shipping';
import Product from './Product';

const reducers = {
  settings: Settings,
  auth: Auth,
  dashboard: Dashboard,
  ecommerce: Ecommerce,
  common: CommonReducer,
  config: ConfigReducer,
  chatApp: ChatApp,
  mailApp: MailApp,
  contactApp: ContactApp,
  scrumboardApp: ScrumBoardReducer,
  wallApp: WallApp,
  todoApp: ToDoApp,
  garapinUsers: GarapinUser,
  garapinMitra: GarapinMitra,
  orders: Orders,
  permintaan: Permintaan,
  permintaan_rerejuna: PermintaanRerejuna,
  templates: Templates,
  discussion: Discussion,
  garapinSingleMitra: GarapinSingleMitra,
  shipping: Shipping,
  product: Product,
};

export default reducers;
