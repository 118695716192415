import {
    GET_USER_LIST_INIT,
    GET_USER_LIST_SUCCESS,
    GET_USER_LIST_ERROR,
    GarapinUserActions
  } from '../../types/actions/GarapinUser.action';
  
  const initState = {
    list: [],
    loading: false,
    updated: false,
    error: false,
    errorMessage:"",
  };
  
  const garapinUsers = (state = initState, action:GarapinUserActions) => {
    switch (action.type) {
      case GET_USER_LIST_INIT: {
        return {...initState, loading: true};
      }
      case GET_USER_LIST_SUCCESS: {
        return {...state, list: action.payload, loading:false};
      }
      case GET_USER_LIST_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      default:
        return state;
    }
  };
  export default garapinUsers;
  