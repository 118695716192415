
import { Courier } from 'types/models/Shipping';

export const GET_COURIER_DATA = 'GET_COURIER_DATA';

export interface GetCourierAction {
  type: typeof GET_COURIER_DATA;
  payload: Courier[];
}

export type ShippingActionTypes =
  | GetCourierAction;

