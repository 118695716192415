import {
  GET_USER_LIST_ERROR, GET_USER_LIST_SUCCESS, GET_USER_LIST_INIT, GarapinUserActions
  } from '../../types/actions/GarapinUser.action';
  import {appIntl} from '../../@crema/utility/Utils';
import {  getMitra,  getUsers } from '../../@crema/services/db/firebase/users';
import { Dispatch } from 'react';
  
  export const onGetUserList = () => {
    const {messages} = appIntl();
    return async(dispatch:Dispatch<GarapinUserActions>) => {
      dispatch({type: GET_USER_LIST_INIT});
      console.log('test start get user')
      try {
        const users = await getUsers();
        console.log(users,'<<<users in action');
        dispatch({ type: GET_USER_LIST_SUCCESS, payload:users });
        // dispatch({ type: GET_USER_LIST, payload: users });
      } catch (error) {
        dispatch({
          type: GET_USER_LIST_ERROR,
          error: messages['message.somethingWentWrong'] as string,
        });
      }
    };
  };


  export const onDownloadUserList = () => {
    const {messages} = appIntl();
    return async(dispatch:Dispatch<GarapinUserActions>, getState:any) => {
      // dispatch({type: GET_USER_LIST_INIT});
      console.log('test start get user')
      try {
        const users = getState().garapinUsers.list;
        const allMitra:any = await getMitra();
        for(const i in users){
          const mitra:any[] = allMitra.filter((m:any)=> m.path.split('/')[1] === users[i].id) as any[];
          console.log(mitra, 'mitra each user');
          if(mitra.length >0){
            const mitraName = mitra.map((m) => {return m.nama}).join(', ');
            const userFinal = {...users[i], mitra_status:"Produsen", pabrik:mitraName};
            console.log(userFinal);
            users[i] = userFinal;
          }
        }
        console.log(users,'<<<users in action');
        // dispatch({ type: GET_USER_LIST_SUCCESS, payload:users });
        // dispatch({ type: GET_USER_LIST, payload: users });
      } catch (error) {
        dispatch({
          type: GET_USER_LIST_ERROR,
          error: messages['message.somethingWentWrong'] as string,
        });
      }
    };
  };

 
  
