import React from 'react';

export const orderPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/complaint-orders',
        component: React.lazy(() => import('./complaintOrders')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/ongoing-orders',
        component: React.lazy(() => import('./ongoingOrders')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/detail-order/:orderId',
        component: React.lazy(() => import('./detailOrder')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/finished-orders',
        component: React.lazy(() => import('./finishedOrders')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/ongoing-offers',
        component: React.lazy(() => import('./ongoingOffers')),
      },
    ],
  },
];
