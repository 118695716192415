import { ChatRoomType } from "@crema/services/db/firebase/discussion";


const initialState: {
  connectionList: any[];
  userMessages: any[] | null;
  selectedUser: any | null;
  selectedRoom: string;
  error: any | null;
  loadingList: boolean;
} = {
  connectionList: [],
  userMessages: null,
  selectedUser: null,
  selectedRoom: ChatRoomType.CHAT,
  error: null,
  loadingList: false
};

const Discussion = (state = initialState, action: any) => {
  switch (action.type) {
    case 'CHAT_ROOM_LIST_OK': 
      return {
        ...state,
        connectionList: action.payload,
        selectedRoom: ChatRoomType.CHAT,
        loadingList: false,

      };

    case 'PROD_DISCUSSION_ROOM_LIST_OK': 
      return {
        ...state,
        connectionList: action.payload,
        selectedRoom: ChatRoomType.PRODUKSI_DISCUSSION,
        loadingList: false,
      };

    case 'LOADING_DATA': {
      return {
        ...state,
        loadingList: true
      }
    }

    case 'CHAT_SINGLE_OK':
        return {
          ...state,
          userMessages: action.payload,
          loadingList: false,
        }

    case 'SELECT_CHAT': {
      return {
        ...state,
        selectedUser: action.payload,
        userMessages: null,
        loadingList: false,
      };
    }

    case 'ERROR': {
      return  {
        ...state,
        error: action.payload,
        loadingList: false,
      }
    }

    default:
      return state;
  }
};
export default Discussion;
