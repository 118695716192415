
import { BadanUsaha, Category, JumlahPegawai, Satuan } from 'types/models/apps/Config';

export const GET_SATUAN_DATA = 'GET_SATUAN_DATA';
export const GET_CATEGORY_DATA = 'GET_CATEGORY_DATA';
export const GET_BADAN_USAHA_DATA = 'GET_BADAN_USAHA_DATA';
export const GET_JUMLAH_PEGAWAI_DATA = 'GET_JUMLAH_PEGAWAI_DATA';

export interface GetSatuanAction {
  type: typeof GET_SATUAN_DATA;
  payload: Satuan[];
}
export interface GetCategoryAction {
  type: typeof GET_CATEGORY_DATA;
  payload: Category[];
}

export interface GetBadanUsahaAction {
  type: typeof GET_BADAN_USAHA_DATA;
  payload: BadanUsaha[];
}

export interface GetJumlahPegawaiAction {
  type: typeof GET_JUMLAH_PEGAWAI_DATA;
  payload: JumlahPegawai[];
}


export type ConfigActionTypes =
  | GetSatuanAction
  | GetCategoryAction
  | GetBadanUsahaAction
  | GetJumlahPegawaiAction;
