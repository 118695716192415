import { BadanUsaha, Category, JumlahPegawai, Satuan } from 'types/models/apps/Config';
import {
  ConfigActionTypes,
  GET_SATUAN_DATA,
  GET_CATEGORY_DATA,
  GET_BADAN_USAHA_DATA,
  GET_JUMLAH_PEGAWAI_DATA
} from '../../types/actions/Config.action';

const initialState: {
  satuan: Satuan[] | null;
  category: Category[] | null;
  badanUsaha: BadanUsaha[] | null;
  jumlahPegawai: JumlahPegawai[] | null;
} = {
  satuan: null,
  category: null,
  badanUsaha: null,
  jumlahPegawai: null,
};

const Config = (state = initialState, action: ConfigActionTypes) => {
  switch (action.type) {
    case GET_SATUAN_DATA:
      return {
        ...state,
        satuan: action.payload,
      };
    case GET_CATEGORY_DATA:
      return {
        ...state,
        category: action.payload,
      };
    case GET_BADAN_USAHA_DATA:
      return {
        ...state,
        badanUsaha: action.payload,
      };
    case GET_JUMLAH_PEGAWAI_DATA:
      return {
        ...state,
        jumlahPegawai: action.payload,
      };
    

    default:
      return state;
  }
};
export default Config;
