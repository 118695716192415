import React from 'react';

export const shippingPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/shipping/rate',
        component: React.lazy(() => import('./rate')),
      },
    ],
  },
//   {
//     auth: ['user'],
//     routes: [
//       {
//         path: '/garapin-mitra',
//         component: React.lazy(() => import('./garapinMitra')),
//       },
//     ],
//   },
//   {
//     auth: ['user'],
//     routes: [
//       {
//         path: '/add-mitra/:uid',
//         component: React.lazy(() => import('./addMitra'))
//       },
//     ],
//   },
  
];
