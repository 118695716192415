import { firestore } from "@crema/services/auth/firebase/firebase";
import { Product } from "types/models/Product";


export const addNewProduct = async (productId:string, productData:any) => {
    try {
        const snap = await firestore.collection('products').doc(productId.toString()).set(productData, {merge: true});
        return snap;
    } catch (e) {
        throw e;
    }
}

export const getProducts = async () => {
    try {
        const snap  = await firestore.collection('products').get();
        const data = snap.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
        })as Product);
        return data;
    } catch (e) {
        throw (e);
    }
}

export const getSingleProduct = async ( productId: string) => {
    try {
        const snap = await firestore.doc('/products/'+productId).get();
        return snap;
    } catch (e) {
        throw (e);
    }
}


export const updateProduct = async (productData:any, productId:string) => {
    try {
        const snap = await firestore.collection('products/').doc(productId.toString()).update(productData);
        return snap;
    } catch (e) {
        throw (e);
    }
}

export const batchUpdateStatusProduct = async (paths: string [], status: boolean) => {
    try {
        await firestore.runTransaction((t) => {
            return Promise.all(paths.map(path => t.update(firestore.doc(path), {status})))
            }
        )
    } catch (e) {
        throw (e);
    }
}

