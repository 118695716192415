import { firestore } from "@crema/services/auth/firebase/firebase";

export const getMitraCategory = async () => {
    try {
        const snap = await firestore.collection("master").doc('bidang_usaha_produksi').get();
        const data = snap.get('list');
        return data;
    } catch (error) {
        return error;
    }
}

export const addNewMitra = async (uid:string, mitraData:any, mitraID:any) => {
    try {
        const snap = await firestore.collection('users/'+uid+'/mitra_custom').doc(mitraID.toString()).set(mitraData, {merge: true});
        return snap;
    } catch (e) {
        throw e;
    }
}

export const getSingleMitra = async (uid: string, mitraId: string) => {
    try {
        const snap = await firestore.doc('/users/'+uid+'/mitra_custom/'+mitraId).get();
        return snap;
    } catch (e) {
        throw (e);
    }
}


export const updateMitra = async (uid:string, mitraData:any, mitraID:string) => {
    try {
        const snap = await firestore.collection('users/'+uid+'/mitra_custom').doc(mitraID.toString()).update(mitraData);
        return snap;
    } catch (e) {
        throw (e);
    }
}

export const batchUpdateStatusMitra = async (paths: string [], status: boolean) => {
    try {
        await firestore.runTransaction((t) => {
            return Promise.all(paths.map(path => t.update(firestore.doc(path), {status})))
            }
        )
    } catch (e) {
        throw (e);
    }
}

