import {
  GET_ORDER_LIST_ERROR,
  GET_ORDER_LIST_SUCCESS,
  GET_ORDER_LIST_INIT,
  GET_ORDER_ERROR,
  GET_ORDER_SUCCESS,
  GET_ORDER_INIT,
  UPDATE_ORDER_LIST_ERROR,
  UPDATE_ORDER_LIST_SUCCESS,
  UPDATE_ORDER_LIST_INIT,
  UPDATE_ORDER_ERROR,
  UPDATE_ORDER_SUCCESS,
  UPDATE_ORDER_INIT,
  OrdersActions
} from '../../types/actions/Orders.action';
import { appIntl } from '../../@crema/utility/Utils';
import { getOrder, writeOrder } from '../../@crema/services/db/firebase/orders';
import { Dispatch } from 'react';
import { auth } from '@crema/services/auth/firebase/firebase';
import { baseUrl } from '@crema/utility/url';
import Axios from 'axios';

export const onGetComplaintOrders = () => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>) => {
    dispatch({ type: GET_ORDER_LIST_INIT });
    try {
      const status = ["open_complaint", "ongoing_complaint", "canceled_complaint"];
      const orders = await getOrder({status});
      dispatch({ type: GET_ORDER_LIST_SUCCESS, payload: orders });
      // dispatch({ type: GET_ORDER_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_ORDER_LIST_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};
export const onUpdateComplaintOrders = ({ orderId, data }: { orderId: string, data: any }) => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>, getState: any) => {
    dispatch({ type: UPDATE_ORDER_LIST_INIT, payload: { state: getState().orders } });
    try {
      await writeOrder({ data, orderId });
      const ordersNow = getState().orders;
      // console.log(ordersNow)
      const dataOrder = ordersNow.list.map((elem: any) => {
        if (elem.id === orderId) {

          console.log({
            ...elem,
            ...data
          })
          return {
            ...elem,
            ...data
          }
        }
        return elem;
      });
      console.log(dataOrder)
      dispatch({
        type: UPDATE_ORDER_LIST_SUCCESS, payload: {
          state: getState().orders,
          data: dataOrder
        }
      });
    } catch (error) {

      dispatch({
        type: UPDATE_ORDER_LIST_ERROR,
        payload: {
          state: getState().orders,
        },
        error: messages['message.somethingWentWrong'] as string,

      }
      );
    }
  };
};


export const onUpdateOrder = ({ orderId, data }: { orderId: string, data: any }) => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>, getState: any) => {
    dispatch({ type: UPDATE_ORDER_INIT, payload: { state: getState().orders } });
    try {
      await writeOrder({ data, orderId });
      const ordersNow = getState().orders;
      // console.log(ordersNow)
      const dataOrder = {...ordersNow.order, ...data}
      console.log(dataOrder)
      dispatch({
        type: UPDATE_ORDER_SUCCESS, payload: {
          state: getState().orders,
          data: dataOrder
        }
      });
    } catch (error) {

      dispatch({
        type: UPDATE_ORDER_ERROR,
        payload: {
          state: getState().orders,
        },
        error: messages['message.somethingWentWrong'] as string,

      }
      );
    }
  };
};

export const onGetOngoingOrders = () => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>) => {
    dispatch({ type: GET_ORDER_LIST_INIT });
    try {
      const status = ["checked_out_order", "paid_order", "activated_order", "processed_order", "sent_order", "received_order"];
      const orders = await getOrder({status});
      dispatch({ type: GET_ORDER_LIST_SUCCESS, payload: orders });
      // dispatch({ type: GET_ORDER_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_ORDER_LIST_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};

export const onGetOrder =  (orderId:any) => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>, getState:any) => {
    dispatch({ type: GET_ORDER_INIT });
    try {
      const idToken = await auth.currentUser?.getIdToken();
      console.log(idToken, 'id token');
      const headers = {
        Authorization: `Bearer ${idToken}`,
      };
      const params ={
        orderId
      }
      const url = `${baseUrl}/api/order`;
      // const url = 'https://api.biteship.com/v1/rates/couriers';
      // const result = await Axios.post(url,body, {headers});
      const result = await Axios.get(url, { headers, params });
      console.log(result.data);
      dispatch({ type: GET_ORDER_SUCCESS, payload: result.data });
      // dispatch({ type: GET_ORDER_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_ORDER_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};

export const onGetFinishedOrders = () => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>) => {
    dispatch({ type: GET_ORDER_LIST_INIT });
    try {
      const status = ["finished_order", "reviewed_order"];
      const orders = await getOrder({status});
      dispatch({ type: GET_ORDER_LIST_SUCCESS, payload: orders });
      // dispatch({ type: GET_ORDER_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_ORDER_LIST_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};
export const onGetOngoingOffers = () => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<OrdersActions>) => {
    dispatch({ type: GET_ORDER_LIST_INIT });
    try {
      const status = ["new_offer", "accepted_offer"];
      const orders = await getOrder({status, sort: ['created_expired_at'], sortType:['desc']});
      dispatch({ type: GET_ORDER_LIST_SUCCESS, payload: orders });
      // dispatch({ type: GET_ORDER_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_ORDER_LIST_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};


