import {
    GET_TEMPLATE_LIST_INIT,
    GET_TEMPLATE_LIST_SUCCESS,
    GET_TEMPLATE_LIST_ERROR,
    GET_TEMPLATE_INIT,
    GET_TEMPLATE_SUCCESS,
    GET_TEMPLATE_ALL,
    GET_TEMPLATE_ERROR,
    UPDATE_TEMPLATE_LIST_INIT,
    UPDATE_TEMPLATE_LIST_SUCCESS,
    UPDATE_TEMPLATE_LIST_ERROR,
    TemplatesActions
  } from '../../types/actions/Template.action';
  
  const initState = {
    all: [],
    list: [],
    data: {},
    free: [],
    pilihan: [],
    loading: false,
    updated: false,
    error: false,
    errorMessage:"",
  };
  
  const templates = (state = initState, action: TemplatesActions) => {
    switch (action.type) {
      case GET_TEMPLATE_LIST_INIT: {
        return {...initState, loading: true};
      }
      case GET_TEMPLATE_LIST_SUCCESS: {
        return {...state, list: action.payload, loading:false};
      }
      case GET_TEMPLATE_LIST_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      case GET_TEMPLATE_INIT: {
        return {...initState, loading: true};
      }
      case GET_TEMPLATE_SUCCESS: {
        return {...state, data: action.payload.template, free: action.payload.free, pilihan: action.payload.pilihan, loading:false};
      }
      case GET_TEMPLATE_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      case UPDATE_TEMPLATE_LIST_INIT: {
        return {...action.payload.state, loading: true, updated:false};
      }
      case UPDATE_TEMPLATE_LIST_SUCCESS: {
        return {...action.payload.state, loading:false, updated: true};
      }
      case UPDATE_TEMPLATE_LIST_ERROR: {
        return {...action.payload.state, error:true, errorMessage: action.payload.data, loading:false};
      }
      case GET_TEMPLATE_ALL: {
        return {...action.payload.state, all: action.payload};
      }
      default:
        return state;
    }
  };
  export default templates;
  