import firebase from 'firebase/app';
import 'firebase/storage';
import { firestore } from '../auth/firebase/firebase';

export const storage = firebase.storage();

export const getCollections = async ({ collection, sort }:{collection:string, sort?:string}) => {
    console.log('jalan');
    try {
        const snap = sort?await firestore.collection(collection).orderBy(sort,'desc').get():await firestore.collection(collection).get();
        const data = snap.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        return data;
    } catch (error) {
        return error;
    }
}


export const fetchRemoteConfig = async (key) => {
    
}
export const getSubCollections = async ({ collection }:{collection:string}) => {
    console.log('jalan');
    try {
        const snap = await firestore.collectionGroup(collection).get();
        const data = snap.docs.map(doc => ({
            id: doc.id,
            path: doc.ref.path,
            ...doc.data()
        }));
        return data;
    } catch (error) {
        return error;
    }
}

export const getSubCollectionsMitra = async ({ collection }:{collection:string}) => {
    console.log('jalan');
    try {
        const snap = await firestore.collectionGroup(collection).where('deleted', '==', false).get();
        console.log(snap);
        const data = snap.docs.map(doc => ({
            id: doc.id,
            path: doc.ref.path,
            ...doc.data()
        }));
        return data;
    } catch (error) {
        return error;
    }
}

export const setUserPoin = async (uid:string, poin:any) => {
    try {
        console.log(uid);
        console.log(poin);
        const ref = firestore.collection('users').doc(uid);
        ref.update({
            points: parseInt(poin)
        })
    } catch (error) {
        return error;
    }
}

export const writeDoc = async ({path, data}:{path:string, data:any})=>{
    try {
        const ref = firestore.doc(path);
        await ref.set(data,{merge:true});
        return {
            error: 0,
            ...data
        }
    } catch (error) {
        return {
            error: 1,
            message: error
        }
        
    }
}