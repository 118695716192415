import React from 'react';

export const templatePagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/:uid/:mitraId/:mitraName/templates',
        component: React.lazy(() => import('./listTemplate')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/:uid/:mitraId/add-template',
        component: React.lazy(() => import('./formTemplate')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/:uid/:mitraId/:templateId/edit-template',
        component: React.lazy(() => import('./formTemplate')),
      },
    ],
  },
  
];
