import { GET_COURIER_DATA, ShippingActionTypes } from 'types/actions/Shipping.action';
import { Courier } from 'types/models/Shipping';


const initialState: {
  courierData: Courier[] | null;
  
} = {
  courierData: null,
};

const Shipping = (state = initialState, action: ShippingActionTypes) => {
  switch (action.type) {
    case GET_COURIER_DATA:
      return {
        ...state,
        courierData: action.payload,
      };  

    default:
      return state;
  }
};
export default Shipping;
