// action strings
export const GET_TEMPLATE_LIST_INIT = 'GET_TEMPLATE_LIST';
export const GET_TEMPLATE_LIST_SUCCESS = 'GET_TEMPLATE_LIST_SUCCESS';
export const GET_TEMPLATE_LIST_ERROR = 'GET_TEMPLATE_LIST_ERROR';
export const GET_TEMPLATE_INIT = 'GET_TEMPLATE';
export const GET_TEMPLATE_SUCCESS = 'GET_TEMPLATE_SUCCESS';
export const GET_TEMPLATE_ALL = 'GET_TEMPLATE_ALL';
export const GET_TEMPLATE_ERROR = 'GET_TEMPLATE_ERROR';
export const UPDATE_TEMPLATE_LIST_INIT = 'UPDATE_TEMPLATE_LIST';
export const UPDATE_TEMPLATE_LIST_SUCCESS = 'UPDATE_TEMPLATE_LIST_SUCCESS';
export const UPDATE_TEMPLATE_LIST_ERROR = 'UPDATE_TEMPLATE_LIST_ERROR';

export interface GetTemplateStartAction {
  type: typeof GET_TEMPLATE_LIST_INIT;
}

export interface GetTemplateSuccessAction {
  type: typeof GET_TEMPLATE_LIST_SUCCESS;
  payload: any;
}
export interface GetAllTemplateAction {
  type: typeof GET_TEMPLATE_ALL;
  payload: any;
}

export interface GetTemplateErrorAction {
  type: typeof GET_TEMPLATE_LIST_ERROR;
  error: string;
}

export interface GetTemplateDocStartAction {
  type: typeof GET_TEMPLATE_INIT;
  payload: any;
}
export interface GetTemplateDocSuccessAction {
  type: typeof GET_TEMPLATE_SUCCESS;
  payload: any;
}

export interface GetTemplateDocErrorAction {
  type: typeof GET_TEMPLATE_ERROR;
  error: string;
}

export interface UpdateTemplateStartAction {
  type: typeof UPDATE_TEMPLATE_LIST_INIT;
  payload: any;
}

export interface UpdateTemplateSuccessAction {
  type: typeof UPDATE_TEMPLATE_LIST_SUCCESS;
  payload: any;
}

export interface UpdateTemplateErrorAction {
  type: typeof UPDATE_TEMPLATE_LIST_ERROR;
  error: string;
  payload: any
}


export type TemplatesActions =
  | GetTemplateStartAction
  | GetTemplateSuccessAction
  | GetAllTemplateAction
  | GetTemplateErrorAction
  | GetTemplateDocStartAction
  | GetTemplateDocSuccessAction
  | GetTemplateDocErrorAction
  | UpdateTemplateStartAction
  | UpdateTemplateSuccessAction
  | UpdateTemplateErrorAction;
