// import Api from '../../@crema/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import { auth } from '@crema/services/auth/firebase/firebase';
import Axios from 'axios';
import { Courier } from 'types/models/Shipping';
import { GET_COURIER_DATA } from 'types/actions/Shipping.action';
import { baseUrl } from '@crema/utility/url';


export const onGetCourierData = () => {
  return async (dispatch: Dispatch<AppActions>) =>  {
    dispatch(fetchStart());

    try{  
      console.log('Fetching Courier Data...')
      const idToken = await auth.currentUser.getIdToken();
      console.log("idToken", idToken);
      const url = `${baseUrl}/shipping/couriers`;
      console.log('url is: ', url);

      const respo = await Axios.get(`${baseUrl}/shipping/couriers`, {
        headers: {
          Authorization: `Bearer ${idToken}`
        }
      });

      console.log("after axios get");

      console.log("la respuesta de api: ", respo);

      if (respo.status === 200) {
        const data:Courier[] = respo.data;
        dispatch({type: GET_COURIER_DATA, payload: data});
        dispatch(fetchSuccess());
      } else {
        console.log(respo);
        dispatch(fetchError('Something went wrong'));
      }
    } catch(err) {
      console.error("error fetching courier!");
      console.error(err)
      dispatch(fetchError('Something went wrong'));
    }
    
  };
};

