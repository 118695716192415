import {
    GET_ORDER_LIST_INIT,
    GET_ORDER_LIST_SUCCESS,
    GET_ORDER_LIST_ERROR,
    GET_ORDER_INIT,
    GET_ORDER_SUCCESS,
    GET_ORDER_ERROR,
    UPDATE_ORDER_LIST_INIT,
    UPDATE_ORDER_LIST_SUCCESS,
    UPDATE_ORDER_LIST_ERROR,
    UPDATE_ORDER_INIT,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_ERROR,
    OrdersActions
  } from '../../types/actions/Orders.action';
  
  const initState = {
    list: [],
    order:{},
    loading: false,
    updated: false,
    error: false,
    errorMessage:"",
  };
  
  const orders = (state = initState, action: OrdersActions) => {
    switch (action.type) {
      case GET_ORDER_LIST_INIT: {
        return {...initState, loading: true};
      }
      case GET_ORDER_LIST_SUCCESS: {
        return {...state, list: action.payload, loading:false};
      }
      case GET_ORDER_LIST_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      case GET_ORDER_INIT: {
        return {...initState, loading: true};
      }
      case GET_ORDER_SUCCESS: {
        return {...state, order: action.payload, loading:false};
      }
      case GET_ORDER_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      case UPDATE_ORDER_LIST_INIT: {
        return {...action.payload.state, loading: true};
      }
      case UPDATE_ORDER_LIST_SUCCESS: {
        return {...action.payload.state, list: action.payload.data, loading:false};
      }
      case UPDATE_ORDER_LIST_ERROR: {
        return {...action.payload.state, error:true, errorMessage: action.error, loading:false};
      }
      case UPDATE_ORDER_INIT: {
        return {...action.payload.state, loading: true};
      }
      case UPDATE_ORDER_SUCCESS: {
        return {...action.payload.state, order: action.payload.data, loading:false};
      }
      case UPDATE_ORDER_ERROR: {
        return {...action.payload.state, error:true, errorMessage: action.error, loading:false};
      }
      default:
        return state;
    }
  };
  export default orders;
  