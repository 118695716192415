// action strings
export const GET_MITRA_LIST_INIT = 'GET_MITRA_LIST';
export const GET_MITRA_LIST_SUCCESS = 'GET_MITRA_LIST_SUCCESS';
export const GET_MITRA_LIST_ERROR = 'GET_MITRA_LIST_ERROR';
export const GET_SINGLE_MITRA_INIT = 'GET_SINGLE_MITRA';
export const GET_SINGLE_MITRA_SUCCESS = 'GET_SINGLE_MITRA_SUCCESS';
export const GET_SINGLE_MITRA_ERROR = 'GET_SINGLE_MITRA_ERROR';

export interface GetMitraStartAction {
  type: typeof GET_MITRA_LIST_INIT;
}

export interface GetMitraSuccessAction {
  type: typeof GET_MITRA_LIST_SUCCESS;
  payload: any;
}

export interface GetMitraErrorAction {
  type: typeof GET_MITRA_LIST_ERROR;
  error: string;
}

export interface GetSingleMitraStart {
  type: typeof GET_SINGLE_MITRA_INIT;
  payload?: any|null;
  error?: null;
}
export interface GetSingleMitraSuccess {
  type: typeof GET_SINGLE_MITRA_SUCCESS;
  payload: any|null;
  error?:null;
}
export interface GetSingleMitraError {
  type: typeof GET_SINGLE_MITRA_ERROR;
  payload?: any|null;
  error:any;
}




export type GarapinMitraActions =
  | GetMitraStartAction
  | GetMitraSuccessAction
  | GetMitraErrorAction | GetSingleMitraStart | GetSingleMitraSuccess | GetSingleMitraError;
