import {
  GarapinMitraActions, GET_SINGLE_MITRA_INIT, GET_SINGLE_MITRA_SUCCESS, GET_SINGLE_MITRA_ERROR
} from '../../types/actions/GarapinMitra.action';
import { appIntl } from '../../@crema/utility/Utils';
import { Dispatch } from 'react';
import { getSingleMitra } from '@crema/services/db/firebase/mitra';

export const GetSingleMitra = (userId: string, mitraId: string) => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<GarapinMitraActions>) => {
    dispatch({ type: GET_SINGLE_MITRA_INIT });
    console.log('test mitra get single mitra profile')
    try {
      const mitraData = await getSingleMitra(userId, mitraId);
      console.log(mitraData, '<<<users in action');
      dispatch({ type: GET_SINGLE_MITRA_SUCCESS, payload: mitraData });
      // dispatch({ type: GET_MITRA_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_SINGLE_MITRA_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};

