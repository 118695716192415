// import Api from '../../@crema/services/ApiConfig';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import {AppActions} from '../../types';
import {Dispatch} from 'redux';
import { addNewProduct, getProducts } from '@crema/services/db/firebase/product';
import { CREATE_PRODUCT_DATA, GET_PRODUCT_LIST } from 'types/actions/Product.action';
import { Product } from 'types/models/Product';
import firebase from 'firebase/app';
import { uploadStorage } from '@crema/services/db/firebase/storage';
import { v4 as uuidv4 } from 'uuid';

export const onGetProductList = () => {
  return async (dispatch: Dispatch<AppActions>) =>  {
    dispatch(fetchStart());

    getProducts()
    .then((products)=>{
      dispatch({type: GET_PRODUCT_LIST, payload: products});
      dispatch(fetchSuccess());
    })
    .catch((err) => 
    { dispatch(fetchError('Something went wrong'));}
      
      )
    
  };
};

const getFileExt = async (fname:any) => {
  return fname.slice((fname.lastIndexOf(".") - 1 >>> 0) + 2);
}

export const createProduct = ({product}:{product:Product}) => {
  return async (dispatch: Dispatch<AppActions>) =>  {
    dispatch(fetchStart());
    const productId = uuidv4();
    const date = new Date();
    product.created_at = firebase.firestore.Timestamp.fromDate(date);
    let images =[];
    const dirPath = 'mobilecontent/product/'
    for(const img of product.img){
      // const fd = new FormData();
      // fd.append('file', img.file);
      // fd.set('custom_path', 'product')
      // const res = await Axios.post(`${baseUrl}/storeImage`, fd).catch(err=>console.log(err));
      // console.log(res, 'image');
      const fileName = 'media_'  + Date.now() + '.' + (await getFileExt(img.file.name));
        console.log(fileName);
      
        const certFile = await uploadStorage(dirPath + fileName, img.file);
        const url = await certFile.ref.getDownloadURL();
        // const uri = certFile.ref.fullPath;
      images.push(url);
    }
    product.img = images;
    product.active = true;
    addNewProduct(productId.toString(), product)
    .then((products)=>{
      dispatch({type: CREATE_PRODUCT_DATA, payload: product});
      dispatch(fetchSuccess());
    })
    .catch((err) => 
    { dispatch(fetchError('Something went wrong'));}
      
      )
    
  };
}
 