import { firestore } from "@crema/services/auth/firebase/firebase";
import { getCollections, getSubCollectionsMitra } from "../firebaseCRUD"

const getUsers = async () => {
    const snapshot = await getCollections({collection:'users', sort:'createdAt'});
    console.log(snapshot,'<<< firebase service users');
    return snapshot;
}

const totalUsers = async () => {
    const snapshot = await firestore.collection('users').get()
    console.log(snapshot.docs.length)
    return snapshot.docs.length.toString
}

const getMitra = async () => {
    const snapshot = await getSubCollectionsMitra({collection:'mitra_custom'})
    console.log(snapshot,'<<< firebase service mitra');
    return snapshot;
}

const getAllTemplate = async () => {
    const snapshot = await getSubCollectionsMitra({collection:'template'})
    console.log(snapshot,'<<< firebase service template');
    return snapshot;
}
const getMitraUser = async (path:string) => {
    const snapshot = await getCollections({collection:path});
    console.log(snapshot,'<<< firebase service mitra');
    return snapshot;
}

const getUserDoc = async (uid:string) => {
    const snap = await firestore.collection('users').doc(uid).get()
    return snap
}

export { getUsers, totalUsers, getMitra, getUserDoc, getMitraUser, getAllTemplate }