import {
  GET_MITRA_LIST_ERROR, GET_MITRA_LIST_SUCCESS, GET_MITRA_LIST_INIT, GarapinMitraActions
} from '../../types/actions/GarapinMitra.action';
import { getMitra } from '../../@crema/services/db/firebase/users';
import { appIntl } from '../../@crema/utility/Utils';
import { Dispatch } from 'react';

export const onGetMitraList = () => {
  const { messages } = appIntl();
  return async (dispatch: Dispatch<GarapinMitraActions>) => {
    dispatch({ type: GET_MITRA_LIST_INIT });
    console.log('test mitra get user')
    try {
      const users = await getMitra();
      console.log(users, '<<<users in action');
      dispatch({ type: GET_MITRA_LIST_SUCCESS, payload: users });
      // dispatch({ type: GET_MITRA_LIST, payload: users });
    } catch (error) {
      dispatch({
        type: GET_MITRA_LIST_ERROR,
        error: messages['message.somethingWentWrong'] as string,
      });
    }
  };
};

