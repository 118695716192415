// action strings
export const GET_USER_LIST_INIT = 'GET_USER_LIST';
export const GET_USER_LIST_SUCCESS = 'GET_USER_LIST_SUCCESS';
export const GET_USER_LIST_ERROR = 'GET_USER_LIST_ERROR';

export interface GetUserStartAction {
  type: typeof GET_USER_LIST_INIT;
}

export interface GetUserSuccessAction {
  type: typeof GET_USER_LIST_SUCCESS;
  payload: any;
}

export interface GetUserErrorAction {
  type: typeof GET_USER_LIST_ERROR;
  error: string;
}


export type GarapinUserActions =
  | GetUserStartAction
  | GetUserSuccessAction
  | GetUserErrorAction;
