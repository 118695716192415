import React from 'react';

export const userPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/garapin-users',
        component: React.lazy(() => import('./garapinUser')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/garapin-mitra/edit/:uid/:idMitra',
        component: React.lazy(() => import('./garapinMitra/edit')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/add-mitra/:uid',
        component: React.lazy(() => import('./addMitra'))
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/garapin-mitra',
        component: React.lazy(() => import('./garapinMitra')),
      },
    ],
  },
  
  
];
