import { firestore } from "@crema/services/auth/firebase/firebase";
import { writeDoc } from "../firebaseCRUD"
const _ = require('lodash');

export const getOrder = async ({status,sort, sortType}:{status:Array<string>,sort?:Array<string>, sortType?:Array<string>}) =>{
    try {
        const snap = status?await firestore.collection("orders").where("status",'in',status).get():await firestore.collection("orders").get();
        var data = snap.docs.map(doc => ({
            id: doc.id,
            ...doc.data()
        }));
        if(sort!== undefined)data = _.orderBy(data,sort,sortType);
        return data;
    } catch (error) {
        return error;
    }
}

export const writeOrder = async ({data, orderId}:{data:any, orderId:String}) => {
    const path = `orders/${orderId}`;
    const result = await writeDoc({path, data});
    return result;
}