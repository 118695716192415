// action strings
export const GET_ORDER_LIST_INIT = 'GET_ORDER_LIST';
export const GET_ORDER_LIST_SUCCESS = 'GET_ORDER_LIST_SUCCESS';
export const GET_ORDER_LIST_ERROR = 'GET_ORDER_LIST_ERROR';
export const GET_ORDER_INIT = 'GET_ORDER';
export const GET_ORDER_SUCCESS = 'GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR = 'GET_ORDER_ERROR';
export const UPDATE_ORDER_LIST_INIT = 'UPDATE_ORDER_LIST';
export const UPDATE_ORDER_LIST_SUCCESS = 'UPDATE_ORDER_LIST_SUCCESS';
export const UPDATE_ORDER_LIST_ERROR = 'UPDATE_ORDER_LIST_ERROR';
export const UPDATE_ORDER_INIT = 'UPDATE_ORDER';
export const UPDATE_ORDER_SUCCESS = 'UPDATE_ORDER_SUCCESS';
export const UPDATE_ORDER_ERROR = 'UPDATE_ORDER_ERROR';

export interface GetOrderStartAction {
  type: typeof GET_ORDER_LIST_INIT;
}

export interface GetOrderSuccessAction {
  type: typeof GET_ORDER_LIST_SUCCESS;
  payload: any;
}

export interface GetOrderErrorAction {
  type: typeof GET_ORDER_LIST_ERROR;
  error: string;
}
export interface GetOneOrderStartAction {
  type: typeof GET_ORDER_INIT;
}

export interface GetOneOrderSuccessAction {
  type: typeof GET_ORDER_SUCCESS;
  payload: any;
}

export interface GetOneOrderErrorAction {
  type: typeof GET_ORDER_ERROR;
  error: string;
}

export interface UpdateOrderStartAction {
  type: typeof UPDATE_ORDER_LIST_INIT;
  payload: any;
}

export interface UpdateOrderSuccessAction {
  type: typeof UPDATE_ORDER_LIST_SUCCESS;
  payload: any;
}

export interface UpdateOrderErrorAction {
  type: typeof UPDATE_ORDER_LIST_ERROR;
  error: string;
  payload:any;
}
export interface UpdateOneOrderStartAction {
  type: typeof UPDATE_ORDER_INIT;
  payload: any;
}

export interface UpdateOneOrderSuccessAction {
  type: typeof UPDATE_ORDER_SUCCESS;
  payload: any;
}

export interface UpdateOneOrderErrorAction {
  type: typeof UPDATE_ORDER_ERROR;
  error: string;
  payload:any;
}


export type OrdersActions =
  | GetOrderStartAction
  | GetOrderSuccessAction
  | GetOrderErrorAction
  | GetOneOrderStartAction
  | GetOneOrderSuccessAction
  | GetOneOrderErrorAction
  | UpdateOrderStartAction
  | UpdateOrderSuccessAction
  | UpdateOrderErrorAction
  | UpdateOneOrderStartAction
  | UpdateOneOrderSuccessAction
  | UpdateOneOrderErrorAction;
