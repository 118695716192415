import React from 'react';

export const productPagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/products',
        component: React.lazy(() => import('./ListProduct')),
      },
      {
        path: '/product/:productId',
        component: React.lazy(() => import('./ProductPage')),
      },
      {
        path: '/product/new',
        component: React.lazy(() => import('./ProductPage')),
      },
    ],
  },
];
