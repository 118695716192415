import {
    GET_MITRA_LIST_INIT,
    GET_MITRA_LIST_SUCCESS,
    GET_MITRA_LIST_ERROR,
    GarapinMitraActions
  } from '../../types/actions/GarapinMitra.action';
  
  const initState = {
    list: [],
    loading: false,
    updated: false,
    error: false,
    errorMessage:"",
  };
  
  const GarapinMitra = (state = initState, action:GarapinMitraActions) => {
    switch (action.type) {
      case GET_MITRA_LIST_INIT: {
        return {...initState, loading: true};
      }
      case GET_MITRA_LIST_SUCCESS: {
        return {...state, list: action.payload, loading:false};
      }
      case GET_MITRA_LIST_ERROR: {
        return {...state, error:true, errorMessage: action.error, loading:false};
      }
      default:
        return state;
    }
  };
  export default GarapinMitra;
  