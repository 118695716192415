
import {Dispatch} from 'redux';
import {fetchError, fetchStart, fetchSuccess} from './Common';
import { ChatRoomType, getChatRooms, getSingleRoomMsg } from '@crema/services/db/firebase/discussion';

export const getChatList = () => {
  // const {messages} = appIntl();
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchStart());
    try {
      // const cRoom:any = [];
      const cRoom = await getChatRooms(ChatRoomType.CHAT);
      dispatch(fetchSuccess());
      dispatch({type: 'CHAT_ROOM_LIST_OK', payload: cRoom??[]});
    } catch (e) {
      dispatch(fetchError(JSON.stringify(e)));
    }
  };
};

export const getProduksiDiscussionList = () => {
  // const {messages} = appIntl();
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchStart());
    try {
      // const cRoom:any = [];
      dispatch({type:  'LOADING_DATA'});
      const cRoom = await getChatRooms(ChatRoomType.PRODUKSI_DISCUSSION);
      dispatch(fetchSuccess());
      dispatch({type: 'PROD_DISCUSSION_ROOM_LIST_OK', payload: cRoom??[]});
    } catch (e) {
      dispatch(fetchError(JSON.stringify(e)));
    }
  };
};

export const getChatMsg = (id: string, roomType: string) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(fetchStart());
    try {
      // const cRoom:any = [];
      const roomMsg = await getSingleRoomMsg(id, roomType);
      dispatch(fetchSuccess());
      dispatch({type: 'CHAT_SINGLE_OK', payload: roomMsg??[]});
    } catch (e) {
      dispatch(fetchError(JSON.stringify(e)));
    }
  };
};



export const selectUser = (user: any, roomType: string) => {
  return (dispatch: Dispatch<any>) => {
    console.log("room type: ", roomType);
    dispatch({type: "SELECT_CHAT", payload: user});
    dispatch(getChatMsg(user.id, roomType));
  };
};
