// action strings
export const GET_PERMINTAAN_REREJUNA_LIST_INIT = 'GET_PERMINTAAN_REREJUNA_LIST';
export const GET_PERMINTAAN_REREJUNA_LIST_SUCCESS = 'GET_PERMINTAAN_REREJUNA_LIST_SUCCESS';
export const GET_PERMINTAAN_REREJUNA_LIST_ERROR = 'GET_PERMINTAAN_REREJUNA_LIST_ERROR';
export const GET_PERMINTAAN_REREJUNA_INIT = 'GET_PERMINTAAN_REREJUNA';
export const GET_PERMINTAAN_REREJUNA_SUCCESS = 'GET_PERMINTAAN_REREJUNA_SUCCESS';
export const GET_PERMINTAAN_REREJUNA_ERROR = 'GET_PERMINTAAN_REREJUNA_ERROR';
export const UPDATE_PERMINTAAN_REREJUNA_LIST_INIT = 'UPDATE_PERMINTAAN_REREJUNA_LIST';
export const UPDATE_PERMINTAAN_REREJUNA_LIST_SUCCESS = 'UPDATE_PERMINTAAN_REREJUNA_LIST_SUCCESS';
export const UPDATE_PERMINTAAN_REREJUNA_LIST_ERROR = 'UPDATE_PERMINTAAN_REREJUNA_LIST_ERROR';

export interface GetPermintaanStartAction {
  type: typeof GET_PERMINTAAN_REREJUNA_LIST_INIT;
}

export interface GetPermintaanSuccessAction {
  type: typeof GET_PERMINTAAN_REREJUNA_LIST_SUCCESS;
  payload: any;
}

export interface GetPermintaanErrorAction {
  type: typeof GET_PERMINTAAN_REREJUNA_LIST_ERROR;
  error: string;
}
export interface GetOnePermintaanStartAction {
  type: typeof GET_PERMINTAAN_REREJUNA_INIT;
}

export interface GetOnePermintaanSuccessAction {
  type: typeof GET_PERMINTAAN_REREJUNA_SUCCESS;
  payload: any;
}

export interface GetOnePermintaanErrorAction {
  type: typeof GET_PERMINTAAN_REREJUNA_ERROR;
  error: string;
}

export interface UpdatePermintaanStartAction {
  type: typeof UPDATE_PERMINTAAN_REREJUNA_LIST_INIT;
  payload: any;
}

export interface UpdatePermintaanSuccessAction {
  type: typeof UPDATE_PERMINTAAN_REREJUNA_LIST_SUCCESS;
  payload: any;
}

export interface UpdatePermintaanErrorAction {
  type: typeof UPDATE_PERMINTAAN_REREJUNA_LIST_ERROR;
  error: string;
  payload:any;
}


export type PermintaanRerejunaActions =
  | GetPermintaanStartAction
  | GetPermintaanSuccessAction
  | GetPermintaanErrorAction
  | GetOnePermintaanStartAction
  | GetOnePermintaanSuccessAction
  | GetOnePermintaanErrorAction
  | UpdatePermintaanStartAction
  | UpdatePermintaanSuccessAction
  | UpdatePermintaanErrorAction;
